import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    redirect:"/",
    component: () => import('../views/index.vue'),
    children: [
      {
        path: '/',
        name: 'homepage',
        component: () => import("../views/HomePage.vue"),
      },
      {
        path: '/AdministratorManagement',
        name: 'AdministratorManagement',
        component: () => import("../views/AdministratorManagement.vue"),
      },
      {
        path: '/RoleGroupManagement',
        name: 'RoleGroupManagement',
        component: () => import("../views/RoleGroupManagement.vue"),
      },
      {
        path: '/AddAnnouncement',
        name: 'AddAnnouncement',
        component: () => import("../views/AddAnnouncement.vue"),
      },
      {
        path: '/CarouselChart',
        name: 'CarouselChart',
        component: () => import("../views/CarouselChart.vue"),
      },
      {
        path: '/NewsManagement',
        name: 'NewsManagement',
        component: () => import("../views/NewsManagement.vue"),
      },
      {
        path: '/RegistrationManagement',
        name: 'RegistrationManagement',
        component: () => import("../views/RegistrationManagement.vue"),
      },
      {
        path: '/EnterpriseInformation',
        name: 'EnterpriseInformation',
        component: () => import("../views/EnterpriseInformation.vue"),
      },
      {
        path: '/EnterpriseTraining',
        name: 'EnterpriseTraining',
        component: () => import("../views/EnterpriseTraining.vue"),
      },
      {
        path: '/ExternalArchitecture',
        name: 'ExternalArchitecture',
        component: () => import("../views/ExternalArchitecture.vue"),
      },
      {
        path: '/InternalArchitecture',
        name: 'InternalArchitecture',
        component: () => import("../views/InternalArchitecture.vue"),
      },
      {
        path: '/EmployeeInformation',
        name: 'EmployeeInformation',
        component: () => import("../views/EmployeeInformation.vue"),
      },
      {
        path: '/AdmissionTicket',
        name: 'AdmissionTicket',
        component: () => import("../views/AdmissionTicket.vue"),
      },
      {
        path: '/UserInformation',
        name: 'UserInformation',
        component: () => import("../views/UserInformation.vue"),
      },
      {
        path: '/ExaminationDeclaration',
        name: 'ExaminationDeclaration',
        component: () => import("../views/ExaminationDeclaration.vue"),
      },
      {
        path: '/DeclarationInformation',
        name: 'DeclarationInformation',
        component: () => import("../views/DeclarationInformation.vue"),
      },
      {
        path: '/TestPush',
        name: 'TestPush',
        component: () => import("../views/TestPush.vue"),
      },
      {
        path: '/ResultUpload',
        name: 'ResultUpload',
        component: () => import("../views/ResultUpload.vue"),
      },
      {
        path: '/InternalDocuments',
        name: 'InternalDocuments',
        component: () => import("../views/InternalDocuments.vue"),
      },
      {
        path: '/ResultStatistics',
        name: 'ResultStatistics',
        component: () => import("../views/ResultStatistics.vue"),
      },
      {
        path: '/NumberUpload',
        name: 'NumberUpload',
        component: () => import("../views/NumberUpload.vue"),
      },
      {
        path: '/OccupationType',
        name: 'OccupationType',
        component: () => import("../views/OccupationType.vue"),
      },
      {
        path: '/ShareData',
        name: 'ShareData',
        component: () => import("../views/ShareData.vue"),
      },
      {
        path: '/DataDownload',
        name: 'DataDownload',
        component: () => import("../views/DataDownload.vue"),
      },
      {
        path: '/ExaminationManagement',
        name: 'ExaminationManagement',
        component: () => import("../views/ExaminationManagement.vue"),
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("../views/Login/Login.vue"),
    meta: {
      title: '登录'
    }
  },
 
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <router-view></router-view>
</template>
<script>
import Global from "@/ts/Global";
import { provide, inject } from "vue";
import Tools from "./ts/Tools";
export default {
  setup() {
    let user_vo = Global._LoginUserVO;
    if (Tools.IsNull(user_vo.uid)) Global.GetLoginUserVOAsync();

    let premission_list = Global.premission_list;
    provide("user_vo", user_vo);
    provide("premission_list", premission_list);
    return {
      user_vo,
    };
  },
  components: {},
  data() {
    return {};
  },
  created() {
    Global.app = this;
    if (Tools.IsNull(Global.LoginToken)) {
      Tools.GoTo("/login");
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style>
html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
#app {
  width: auto;
  color: #333;
  width: 100%;
  height: 100%;
}
</style>

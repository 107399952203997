import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import "@/ts/SuperExt";
import Global from '@/ts/Global';
import Config from './ts/Config'
import { VueUeditorWrap } from "vue-ueditor-wrap";
import * as echarts from 'echarts'
Global.Echarts = echarts;
let app=createApp(App)
app.config.globalProperties.R = (url:string) => {
    let host = Config.ResourceHost;
    if (url.startsWith("http")) {
        //切碎它
        let arr = url.split("/");
        url = "/" + arr[arr.length - 1];
    }
    return `${host}${url}`;
};
app.use(router)
app.use(ElementPlus)
app.use(VueUeditorWrap)
app.mount('#app')

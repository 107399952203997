import router from '@/router';
import { ElMessage } from 'element-plus'
import Config from './Config';
import Global from './Global';
export default class Tools {
    /**
     * 获取当前的Get传参
     * @returns 
     */
    public static GetQuery(): any {
        return Global.app.$route.query;
    }
    /**
     * 弹出消息框
     */
    static Alert(message: string, type: "success" | "error" = "success") {
        ElMessage({
            message: message,
            type: type
        });
    }


    static ToJsonVO(str: string): {
        flag: boolean,
        data: any
    } {
        try {
            return {
                flag: true,
                data: JSON.parse(str)
            };
        }
        catch (ex) {
            return {
                flag: false,
                data: null
            }
        }
    }


    /**
     * 弹出成功消息
     * @param SUCCESS_MESSAGE 
     */
    static AlertSuccess(SUCCESS_MESSAGE: string): void {
        ElMessage({
            message: SUCCESS_MESSAGE,
            type: "success"
        });
    }

    /**
     * 弹出失败消息
     * @param ERROR_MESSAGE 
     */
    static AlertError(ERROR_MESSAGE: string | undefined) {

        ElMessage.error(ERROR_MESSAGE);
    }


    static IsJson(arg0: string): boolean {
        try {
            if (JSON.parse(arg0))
                return true;
        }
        catch (e) {

        }
        return false;
    }

    /**
     * 检测是否为空
     * @param v 
     * @returns 
     */
    public static IsNull(v?: string): boolean {
        if (v == null || v == "null" || v == undefined || v == "undefined" || v == "")
            return true;
        return false;
    }
    /**
     * 是否不是空
     * @param v 
     * @returns 
     */
    public static IsNotNull(v?: string): boolean {
        return !this.IsNull(v);
    }

    /**
    * 时间戳转化为时分秒
    * @param timestamp 时间戳
    */
    static UnixTimestampToDateTime(timestamp: number) {
        let date = new Date(timestamp)
        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
        return Y + M + D + h + m + s
    }

    /**
      * 取文件后缀
      */
    static GetFileExtension(url: string) {
        try {
            if (url == null)
                throw "";
            if (typeof url != "string")
                throw "";
            let urlList = url.split('.')
            let suffix = urlList[urlList.length - 1]
            return suffix?.trim() ?? "";
        }
        catch (ex) {
            return ""
        }
    }

    /**
     * 执行导出
     * @param action 
     * @param data 
     */
    static ToExport(action: string, data: any): boolean {
        try {
            if (data == null)
                data = {};
            let url = `${Config.API_URL}?a=${action}`;
            data.login_token = Global.LoginToken;
            for (let key in data) {
                if (data[key] != null && data[key] != undefined) {
                    url += `&${key}=${data[key]}`;
                }
            }
            if (url.endsWith('&'))
                url = url.substring(0, url.length - 1);
            window.location.href = url;
            return true;
        }
        catch (e) {
            return false;
        }
    }

    /**
     * 尝试下载文件
     */
    public static DownLoadFile(url: string, fileName: string = '未知文件') {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.setAttribute('target', '_blank');
        /*
         * download的属性是HTML5新增的属性
         * href属性的地址必须是非跨域的地址，如果引用的是第三方的网站或者说是前后端分离的项目(调用后台的接口)，这时download就会不起作用。
         * 此时，如果是下载浏览器无法解析的文件，例如.exe,.xlsx..那么浏览器会自动下载，但是如果使用浏览器可以解析的文件，比如.txt,.png,.pdf....浏览器就会采取预览模式
         * 所以，对于.txt,.png,.pdf等的预览功能我们就可以直接不设置download属性(前提是后端响应头的Content-Type: application/octet-stream，如果为application/pdf浏览器则会判断文件为 pdf ，自动执行预览的策略)
         */
        fileName && a.setAttribute('download', fileName);
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    /**
     * 上传文件
     * @param files 
     */
    static async UploadFileAsync(action: string, data: any, files: File[]): Promise<{
        flag: boolean,
        data: {
            OriginalFileNameNoExtension: string,
            OriginalFileName: string,
            FileName: string,
            FileURL: string,
            FileSize: number,
            MimeType: string,
            FileHash: string
        } | null
    }> {
        let res = await Tools.GetHtmlAsync(action, data, files);
        if (Tools.CheckHtmlIsOK(res, false)) {
            return {
                flag: true,
                data: res.data
            };
        }
        else {
            return {
                flag: false,
                data: null
            };
        }
    }

    /**
     * 获取HTML
     * @param action 
     * @param data 
     * @param success 
     * @param fail 
     */
    static GetHtml(action: string, data: any, success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }) => void, fail?: (err: any) => void, files: File[] = []) {
        try {
            if (data == null)
                data = {};

            // Tools.ShowLog(action, "HTML请求", "	#1C86EE");
            data.login_token = Global.LoginToken;

            let url = `${Config.API_URL}?a=${action}`;

            //判断是否是LookBaoApp
            var xhr = new XMLHttpRequest();
            var formdata: FormData | string;
            if (files.length > 0) {
                formdata = new FormData();
                for (let key in data) {
                    if (data[key] != null && data[key] != undefined) {
                        formdata.append(key, data[key]);
                    }
                    // url += "&" + key + "=" + encodeURIComponent(data[key]);
                }
                if (files) {
                    if (files.length > 0) {
                        for (let i = 0; i < files.length; i++) {
                            const f = files[i];
                            if (f)
                                formdata.append('fileUpload', f);
                        }
                    }
                }
            }
            else {
                formdata = "";
                for (let key in data) {
                    if (data[key] != null && data[key] != undefined)
                        formdata += key + "=" + encodeURIComponent(data[key]) + "&";
                }
                if (formdata != "") {
                    formdata = formdata.substr(0, formdata.length - 1);
                }
            }
            xhr.open("POST", url, true);
            if (files.length > 0) {
                // xhr.setRequestHeader("Content-type", "multipart/form-data");
            }
            else {
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            }


            xhr.onload = function (e) {
                try {
                    if (this.status == 200 || this.status == 304) {
                        if (success != null)
                            success(JSON.parse(this.responseText));
                    }
                    else {
                        Tools.AlertError("网络请求失败 错误:" + this.status);
                    }
                }
                catch (ex) {
                    console.error(ex);
                }
            };
            xhr.send(formdata);

        }
        catch (err) {
            console.error(err);
            console.log("加载HTML错误:" + err);
        }
    }

    /**
     * 异步请求
     * @param action 
     * @param data 
     * @param show_jiazaizhong 
     */
    static async GetHtmlAsync(action: string, data: any, files: File[] = []): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }> {

        return new Promise((successfun, errorfun) => {
            this.GetHtml(action, data, (res) => {
                // Tools.ShowLog(`HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                successfun(res);
            }, err => {
                // Tools.ShowLog(`HTML请求出错【${action}】`, "HTML请求出错", "#8B0000");
                errorfun(err);
            }, files);
        });
    }

    /**
     * 检测数据是否正确
     * @param res 
     * @param show 是否显示 默认显示错误提示
     */
    static CheckHtmlIsOK(res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }, show_error_msg: boolean = true) {
        switch (res.state) {
            case -99:
                Tools.GoTo("/Login");
                if (show_error_msg) {

                    Tools.AlertError("您还没有登录!");
                }
                return false;
            case 1:
                return true;
            default:
                if (show_error_msg) {
                    Tools.AlertError(res.error_msg);
                }
                else {
                    console.log(`错误:${res.error_msg}`);
                }
                return false;
        }
    }

    /**
     * 跳转
     * @param href 字符串跳转 或 数字跳转 -1代表返回
     * @param query Get传参
     * @param params Post传参
     * @param to_replice 是否替换方式跳转
     */
    public static GoTo(href: string | number, query: any = {}, params: any = {}, to_replice: boolean = false): void {
        if (typeof href == "string") {
            let name: string = "";
            for (let i = 0; i < router.getRoutes().length; i++) {
                const v = router.getRoutes()[i];
                if (v.path == href) {
                    name = v.name?.toString() as string;
                }
            }

            Global.params_data_dic[href] = params;
            if (to_replice) {
                Global.app.$router.replace({
                    path: href,
                    query: query,
                    name: name,
                    params: params
                });
            }
            else {
                Global.app.$router.push({
                    path: href,
                    query: query,
                    name: name,
                    params: params
                });
            }
        }
        else if (typeof href == "number") {
            switch (href) {
                case -1:
                    Global.app.$router.back();
                    break;
                default:
                    Global.app.$router.go(href);
                    break;
            }
        }
    }


    /**
     * 获取当前的Post传参
     * @returns 
     */
    public static GetParams(href?: any): any {
        if (Tools.IsNull(href)) {
            let v = router;
            let path = v.currentRoute.value.path;

            return Global.params_data_dic[path] ?? {};
        }
        else {
            //path
            if (href) {
                if (typeof href == "string") {
                    return Global.params_data_dic[href] ?? {};
                }
                else if (typeof href == "object") {
                    if (Tools.IsNotNull(href.path)) {
                        return Global.params_data_dic[href.path] ?? {};
                    }
                }
            }

            return {};
        }
    }

    /**
     * 根据URL获取URL名称
     * @param url 
     */
    public static GetURLNameFromURL(url: string): string {
        if (Tools.IsNull(url))
            return "";
        let arr = url.split('/');
        //获取最后一个
        let last_v = arr[arr.length - 1];
        last_v = last_v.split('?')[0];
        last_v = last_v.split('&')[0];
        return last_v;
    }

    /**
     * 设置数组
     * @param to_array 
     * @param from_arr 
     */
    static SetArray(to_array: any[], from_arr: any[]) {
        to_array.Clear();
        to_array.PushAll(from_arr);
    }

    /**
     * 文件转Base64
     * @param file 文件对象
     */
    public static async FileToBase64Async(file: File): Promise<{
        flag: boolean,
        data: string
    }> {
        return await new Promise(r => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    let base64Str: string = reader.result as string;
                    r({
                        flag: true,
                        data: base64Str
                    });
                };
                reader.onerror = (e) => {
                    r({
                        flag: false,
                        data: "转换失败!"
                    });
                };
                reader.readAsDataURL(file);
            }
            catch (ex) {
                r({
                    flag: false,
                    data: ""
                });
            }
        });
    }
}